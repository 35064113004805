/* Ant Menu Style */

// CRM theme
.ant-menu {
  color: @menu-item-color;
  background: @menu-bg;

  &-horizontal {
    background: none;
  }

  &-item-group-title {
    color: @menu-item-group-title-color;
    font-size: @font-size-base;
    line-height: @line-height-base;
    padding: (@sidebar-padding-lr + 10px) @sidebar-padding-lr 10px;
  }

  &-item:active,
  &-submenu-title:active {
    background: @menu-item-active-bg;
  }
  &-item .icon {
    min-width: 14px;
    margin-right: 22px;

    &[class^="icon-"]::before,
    &[class*=" icon-"]::before {
      position: relative;
      top: 2px;
    }
  }

  &-sub &-item .icon {
    font-size: 12px;
  }

  &-item > a {
    color: @menu-item-color;
    &:hover {
      color: @menu-highlight-color;
    }
  }

  &-item-divider {
    background-color: @border-color-split;
  }

  &-submenu-inline {
    .transition(all 0.3s ease-out);
  }

  &-submenu-inline&-submenu-open {
    padding: 12px 0;
  }

  &-item:hover,
  &-item-active,
  &:not(.ant-menu-inline) &-submenu-open,
  &-submenu-active,
  &-submenu-title:hover {
    color: @menu-highlight-color;
  }

  &-horizontal > &-item:hover,
  &-horizontal > &-item-active,
  &-horizontal > &-submenu &-submenu-title:hover {
    background-color: transparent;
  }

  &-item-selected {
    color: @menu-highlight-color;
    > a {
      color: @menu-item-color;
    }

    > a:hover {
      color: @menu-highlight-color;
    }
  }

  &:not(.ant-menu-horizontal) &-item-selected {
    background-color: @menu-item-active-bg;
  }

  &-inline,
  &-vertical,
  &-vertical-left {
    border-right: @border-width-base @border-style-base @border-color-split;
  }
  &-vertical-right {
    border-left: @border-width-base @border-style-base @border-color-split;
  }

  &-item,
  &-submenu-title {
    padding: 0 20px;

    .@{class-prefix}-layout-header-horizontal & {
      padding: 0 10px;

      @media screen and (max-width: @screen-lg-max) {
        padding: 0 6px;
      }
    }

    .@{iconfont-css-prefix} {
      margin-right: 20px;
    }
  }

  & > &-item-divider {
    background-color: @border-color-split;
  }

  &-sub {
    & > li {
      & > a {
        position: relative;
      }
    }
  }

  &-submenu {
    > .ant-menu {
      background-color: @menu-bg;
      border-radius: 0;

      &.ant-menu-sub .ant-menu-item {
        padding-left: 2*@sidebar-padding-lr + 6px !important;
      }
    }

    &-popup {
      border-radius: 0;
      z-index: @zindex-dropdown;

      & .ant-menu-sub {
        &.ant-menu .ant-menu-item {
          padding-left: @sidebar-padding-lr - 10px !important;
        }
      }
    }

    &-vertical,
    &-vertical-left,
    &-vertical-right,
    &-inline {
      .@{menu-prefix-cls}-item:not(:last-child) {
        margin-bottom: 0;
      }

      > .ant-menu-submenu-title .ant-menu-submenu-arrow {
        .@{class-prefix}-app-sidebar & {
          position: relative;
          top: auto;
          right: auto;
        }

        .ant-drawer & {
          position: relative;
          top: auto;
          right: auto;
        }

        &:before,
        &:after {
          background-image: linear-gradient(to right, @menu-item-color, @menu-item-color);
        }
      }
      > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow {
        &:after,
        &:before {
          background: linear-gradient(to right, @menu-highlight-color, @menu-highlight-color);
        }
      }
    }
  }

  &-vertical &-submenu-selected,
  &-vertical-left &-submenu-selected,
  &-vertical-right &-submenu-selected {
    color: @menu-highlight-color;
    > a {
      color: @menu-highlight-color;
    }
  }

  &-horizontal {
    border-bottom: @border-width-base @border-style-base @border-color-split;
    line-height: 46px;
    padding: 0;

    .ant-layout-header & {
      border-bottom: 0 none;
      line-height: @layout-header-height - 2px;
    }

    > .ant-menu-item,
    > .ant-menu-submenu {
      border-bottom: 2px @border-style-base transparent;

      &:hover,
      &-active,
      &-open,
      &-selected {
        border-bottom: 2px @border-style-base @menu-highlight-color;
        color: @menu-highlight-color;
      }

      > a {
        color: @menu-item-color;
        &:hover {
          color: @menu-highlight-color;
        }
        &:before {
          bottom: -2px;
        }
      }
    }
  }

  &-vertical,
  &-vertical-left,
  &-vertical-right,
  &-inline {
    .ant-menu-item {
      &:not(:last-child) {
        margin-bottom: 0;
      }
      &:after {
        border-right: 3px @border-style-base @menu-highlight-color;
      }
    }

    .ant-menu-item,
    .ant-menu-submenu-title {
      padding: 0 @sidebar-padding-lr 0 (@sidebar-padding-lr - 10px) !important;
      font-size: @font-size-base;
      margin-top: 0;
      margin-bottom: 0;
      text-overflow: inherit;

      .ant-layout-sider & {
        padding-left: @sidebar-padding-lr !important;
      }

      .ant-drawer & {
        padding-left: @sidebar-padding-lr !important;
      }

      .icon {
        min-width: 14px;
        margin-right: 20px;

        &[class^="icon-"]::before,
        &[class*=" icon-"]::before {
          position: relative;
          top: 2px;
        }

        & + span {
          .ant-layout-sider-collapsed & {
            max-width: 0;
            display: inline-block;
            opacity: 0;
          }
        }
      }

      .@{menu-prefix-cls}-submenu-arrow {
        .ant-layout-sider-collapsed & {
          display: none;
        }
      }

      .ant-layout-sider-collapsed & {
        padding: 0 (@menu-collapsed-width - 16px) / 2 !important;
      }
    }
  }

  &-inline {
    .ant-menu-submenu-title {
      padding-right: @sidebar-padding-lr !important;
      .flex-display(flex, row, nowrap);
      .align-items(center);
      .justify-content(space-between);
    }
  }

  &-inline-collapsed {
    width: @menu-collapsed-width;
    > .ant-menu-item,
    > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-item,
    > .ant-menu-submenu > .ant-menu-submenu-title {
      left: 0;
      padding: 0 (@menu-collapsed-width - 16px) / 2 !important;
      .@{menu-prefix-cls}-submenu-arrow {
        display: none;
      }
      .icon {
        & + span {
          max-width: 0;
          display: inline-block;
          opacity: 0;
        }
      }
    }
    &-tooltip {
      & .icon {
        display: none;
      }

      a {
        color: @text-color-dark;
      }
    }

    .ant-menu-item-group-title {
      padding-left: 4px;
      padding-right: 4px;
      display: none;
    }
  }

  &-item-group-list {
    .ant-menu-item,
    .ant-menu-submenu-title {
      padding: 0 16px 0 28px;
    }
  }

  &-sub&-inline {
    & > .ant-menu-item,
    & > .ant-menu-submenu > .ant-menu-submenu-title {
      line-height: @menu-item-height;
      height: @menu-item-height;
    }

    & .ant-menu-item-group-title {
      padding: 0 @sidebar-padding-lr;
      line-height: @menu-item-height;
      height: @menu-item-height;
    }
  }

  // Disabled state sets text to gray and nukes hover/tab effects
  &-item-disabled,
  &-submenu-disabled {
    color: @disabled-color !important;
    > a {
      color: @disabled-color !important;
    }
    > .ant-menu-submenu-title {
      color: @disabled-color !important;
    }
  }

  &-submenu-selected .ant-menu-submenu-title {
    color: @menu-highlight-color;

    & .ant-menu-submenu-arrow {
      &:after,
      &:before {
        background: linear-gradient(to right, @menu-highlight-color, @menu-highlight-color);
      }
    }
  }
}

// dark theme
.ant-menu {

  &-dark,
  &-dark &-sub {
    color: @menu-dark-color;
    background: @menu-dark-bg;
    .ant-menu-submenu-title .ant-menu-submenu-arrow {
      &:after,
      &:before {
        background: @menu-dark-arrow-color;
      }
    }
  }

  &-dark &-inline&-sub {
    background: none;
    box-shadow: none;
  }

  &-dark&-horizontal {
    border-bottom-color: @menu-dark-bg;
  }

  &-dark&-horizontal > &-item,
  &-dark&-horizontal > &-submenu {
    border-color: @menu-dark-bg;
  }

  &-dark &-item .icon {
    min-width: 14px;
    margin-right: 20px;

    &[class^="icon-"]::before,
    &[class*=" icon-"]::before {
      position: relative;
      top: 2px;
    }
  }

  &-dark &-item-group-title {
    color: @menu-dark-color;
  }

  &-dark &-item {
    color: @menu-dark-color;
  }

  &-dark &-item > a {
    color: darken(@menu-dark-color, 20%);
  }

  &-dark &-item:hover,
  &-dark &-item-active,
  &-dark &-submenu-active,
  &-dark &-submenu-open,
  &-dark &-submenu-selected,
  &-dark &-submenu-title:hover {
    background-color: fade(@white-color, 12%);
    color: @menu-dark-highlight-color;
    > a {
      color: @menu-dark-highlight-color;
    }
    > .ant-menu-submenu-title,
    > .ant-menu-submenu-title:hover {
      > .ant-menu-submenu-arrow {
        &:after,
        &:before {
          background: @menu-dark-highlight-color;
        }
      }
    }
  }

  &-dark &-item-selected {
    color: @menu-dark-highlight-color;

    > a,
    > a:hover {
      color: @menu-dark-highlight-color;
    }
  }

  &&-dark &-item-selected,
  &-submenu-popup&-dark &-item-selected {
    background-color: fade(@white-color, 09%);
  }

  // Disabled state sets text to dark gray and nukes hover/tab effects
  &-dark &-item-disabled,
  &-dark &-submenu-disabled {
    &,
    > a {
      color: @disabled-color-dark !important;
    }
    > .ant-menu-submenu-title {
      color: @disabled-color-dark !important;
    }
  }

  &-dark .ant-menu-submenu-selected .ant-menu-submenu-title {
    color: @menu-dark-highlight-color;
  }
}

.ant-menu-submenu-horizontal {
  & > .ant-menu-submenu-title {
    color: @header-text-color;
  }
}

