.custom-price-input {
    display: flex;
    width: 380px;

    .ant-form-item {
      flex: 1;  
    }
    .field-suffix {
      padding-left: 10px;
      height: 45px;
      display: inline-flex;
      align-items: center;
    }
    .positive {
        color: green;
    }
    .negative {
        color: red;
    }
}
