.add-new-add-on-form {
  .ant-form-item-label {
    min-width: 130px !important;
    width: 130px;
  }
}

.add-on-selector {
  .ant-form-item-control-wrapper {
    width: 100%;
  }

  .ant-select {
    width: 50%;
  }

  input[type=number] {
    width: 80px;
    margin-left: 12px;
  }

  i.dynamic-delete-button {
    margin-left: 12px;
  }
}
