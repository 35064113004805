.coloredCollapsePanel {
  border-radius: 4px;
  margin-bottom: 24px;
  overflow: 'hidden';
  line-height: 1;

  .ant-collapse-header {
    background-color: #4f7c9242;
    color: white;
    font-weight: bold;
    margin-bottom: 10px;
  }

  .ant-table-wrapper {
    margin-bottom: 15px;
  }

  .panelSubtitle {
    margin-top: 10px;
  }

  .warning {
    color: red;
  }

  .ant-collapse-content-box {
    padding: 0 45px 10px;
  }
}

.coloredCollapsePanelbkg {
  border-radius: 4px;
  margin-bottom: 24px;
  overflow: 'hidden';
  line-height: 1;

  .ant-collapse-header {
    background-color: #4f7c9242;
    color: white;
    font-weight: bold;

  }

  .ant-collapse-content-box {
    background-color: rgb(245, 245, 245, 1);
  }

  .ant-table-wrapper {
    margin-bottom: 15px;
    margin-top: 10px;
  }

  .panelSubtitle {
    margin-top: 10px;
  }

  .warning {
    color: red;
  }
}

.warning {
  color: red;
}

.siteName {
  text-align: center;
}

.capitalize {
  text-transform: capitalize;
}

.testWarning {
  text-transform: capitalize;
  text-align: center;
  background-color: #ED6B30;
  color: whitesmoke;
  font-size: 2em;
  padding: 10px;
  margin: 10px;
}


.planDropdownHeader {
  background-color: #4f7c9242;
  color: rgb(0, 0, 0);
  font-weight: bold;
  margin-bottom: 10px;
  padding: 15px;

}

.dropDown {
  cursor: pointer;
}

.statBox {
  background-color: white;
  min-height: 11em;
  padding-top: 1.5em;
  margin-bottom: 2em;
  text-align: center;
}

.publishStatBox {
  background-color: white;
  min-height: 500px;
  padding-top: 1.5em;
  margin-bottom: 2em;
  text-align: center;
}

.statBoxProgress {
  background-color: white;
  min-height: 11em;
  padding-top: 1.5em;
  margin-bottom: 2em;
  text-align: center;
}


.statTable {
  background-color: white;
  padding-top: 2em;
  height: 24em;

}

.dynamic-delete-button {
  cursor: pointer;
  position: relative;
  top: 4px;
  font-size: 24px;
  color: #999;
  transition: all 0.3s;
}

.dynamic-delete-button:hover {
  color: #777;
}

.word-break-cell {
  word-break: break-word !important;
}

.account-table-container {
  .ant-table-column-title {
    white-space: pre;
  }

  .ant-pagination>li.ant-pagination-options {
    margin-bottom: 10px;
  }
}

.cid-column {
  //  background-color: #b3b3b3;
  text-align: center;
}

.all-sites-table {
  .ant-pagination>li.ant-pagination-options {
    margin-bottom: 10px;
  }
}

.ant-popover-buttons {
  .ant-btn-sm {
    line-height: 20px;
  }
}