.backups-table {
  width: 100%;
  padding-left: 20px;
  padding-right: 20px;
}
.backups-table-header {
  margin-top: 10px;
}

.trigger-backup-form {
  .backup-description {
    flex: 1;
    .ant-form-item-control-wrapper {
      flex: 1
    }
  }
  .ant-form-item-label {
    min-width: 120px;
  }
  .checkmark-button
  {
    .ant-btn {
      height: 30px;
      line-height: 30px;
      margin-bottom: 0px;
      min-width: 100px;
    }
    &.off {
      .ant-btn {
        background-color: white !important;
        span {
          color: #5a94bb !important;
        }
      }
    }
    &.on {
    }
  }
}

.backup-url-warning {
  font-weight: bold;
  color: darkorange;
  text-align: center;
}

.recommended-count {
    margin-top: -10px;
    margin-bottom: 5px;
    text-align: center;
    color: darkorange;
}

.publish-container-warning {
    margin-top: 10px;
    margin-bottom: 15px;
    text-align: center;
    font-weight: bold;
    color: darkorange;
}

.crons-table {
  width: 100%;
  padding-left: 20px;
  padding-right: 20px;
}

.crons-table-header {
  margin-top: 10px;
}