/*Tabs Styles*/
.@{class-prefix}-tabs-half {
  & .ant-tabs-bar {
    margin-bottom: 0;
  }

  & .ant-tabs-nav {
    display: block;

    & .ant-tabs-tab {
      margin: 0;
      width: 50%;
      text-align: center;
    }

    & .ant-tabs-ink-bar {
      width: 50%;
    }
  }
}
