/*Pagination Styles*/
.ant-pagination {
  & > li {
    margin-bottom: 10px;

    &.ant-pagination-options,
    &.ant-pagination-total-text {
      margin-bottom: 0;
    }
  }

  &.mini {
    &:not(:last-child) {
      margin-bottom: 10px;
    }

    & li {
      margin-bottom: 5px !important;
    }
  }
}
