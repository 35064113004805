/*Input Styles*/
.ant-input-search {
  margin-bottom: 16px;

  & > .ant-input-suffix > .ant-input-search-button {
    margin-right: 0;
  }
}

.ant-input-group {

  & > [class*="col-"] {
    padding-left: 0;
    float: left !important;
  }
}

.ant-transfer-list {
  margin-bottom: 10px;
}

.@{class-prefix}-form-group {
  margin-bottom: 10px;
}

// Search Bar Style
.@{class-prefix}-search-bar {
  position: relative;

  & .@{class-prefix}-form-group {
    width: 100%;
    height: 100%;
    margin-bottom: 0;
  }

  & input[type="search"] {
    padding-right: 35px;
    background: fade(@grey-2, 10%);

    &:focus {
      box-shadow: none;
      background-color: fade(@grey-2, 30%);
    }
  }

  & .@{class-prefix}-search-icon {
    background-color: transparent;
    border: 0 none;
    color: lighten(@grey-6, 10%);
    height: 32px;
    width: 30px;
    line-height: 36px;
    text-align: center;

    position: absolute;
    right: 0;
    top: 0;
  }
}

.@{class-prefix}-lt-icon-search-bar {
  & input[type="search"] {
    padding-right: 16px;
    padding-left: 35px;
  }

  & .@{class-prefix}-search-icon {
    left: 0;
    right: auto;
  }
}

.@{class-prefix}-lt-icon-search-bar-lg {
  width: 350px;

  & input[type="search"] {
    .border-radius(0);
    height: 46px;
    padding: 10px 18px 10px 40px;

    @media screen and (max-width: @screen-md-max) {
      height: 38px;
      padding: 5px 18px 5px 40px;
    }
  }

  & .@{class-prefix}-search-icon {
    width: 35px;
    height: 46px;
    line-height: 50px;
    font-size: 16px;
    left: 0;
    right: auto;

    @media screen and (max-width: @screen-md-max) {
      height: 38px;
      line-height: 40px;
    }
  }

  .@{class-prefix}-layout-header-horizontal & {
    width: 200px;
    margin-right: 10px;

    @media screen and (max-width: @screen-lg-max) {
      width: 120px;
    }
  }

  @media screen and (max-width: @screen-lg-max) {
    width: 150px;
  }
}

.@{class-prefix}-popover-search-bar {
  width: 150px;
  margin: -10px -14px;
}

.@{class-prefix}-chat-search-bar {
  .flex(1);
  min-width: 100px;
  width: auto;

  & input[type="search"] {
    .box-shadow(none);
    .border-radius(0);
    height: 40px;

    &:focus {
      background-color: @white-color;
    }
  }

  & .@{class-prefix}-search-icon {
    height: 40px;
    line-height: 44px;
  }
}

.ant-transfer-list {
  width: 150px;

  @media screen and (max-width: @screen-xs-max) {
    width: 100%;
  }
}

@media screen and (max-width: @screen-xs-max) {
  .@{class-prefix}-ant-transfer-width .ant-transfer-list {
    width: 100% !important;
  }
}

@media screen and (max-width: (@screen-xs-max - 100px)) {
  .ant-input-group.ant-input-group-compact {
    & > * {
      .border-radius(4px) !important;
      width: 100% !important;
      border-right-width: 1px;

      &:not(:last-child) {
        margin-bottom: 10px;
      }
    }

    // reset border for Select, DatePicker, AutoComplete, Cascader, Mention, TimePicker
    & > .@{ant-prefix}-select > .@{ant-prefix}-select-selection,
    & > .@{ant-prefix}-calendar-picker .@{ant-prefix}-input,
    & > .@{ant-prefix}-select-auto-complete .@{ant-prefix}-input,
    & > .@{ant-prefix}-cascader-picker .@{ant-prefix}-input,
    & > .@{ant-prefix}-mention-wrapper .@{ant-prefix}-mention-editor,
    & > .@{ant-prefix}-time-picker .@{ant-prefix}-time-picker-input {
      border-radius: 4px;
      border-right-width: 1px;
    }

    & .@{class-prefix}-border-lt-xs {
      border-left: @border-width-base @border-style-base @input-border-color !important;
    }
  }
}



